import React from 'react';
import { Link } from 'react-router-dom'
import Header from '../component/Header'
import Footer from '../component/Footer'

const Hostel = () => {

    return (
        <>
            <Header />
            <div className="breadcrumb mb-0">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex">
                                <h2>Hostel / Boarding Life at St. George’s College</h2>
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li>|</li>
                                    <li>Hostel / Boarding Life</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="inner-section">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h3 class="inner-main-title"><span class="dark-bg">Hostel /  </span> <span class="light-bg"> Boarding Life</span></h3>
                        </div>
                        <div className='col-md-12'>
                            <p>At St. George’s College, Mussoorie, we take pride in providing a nurturing and structured boarding environment that fosters personal growth, independence, and lifelong friendships. Our hostel facilities are designed to offer students a home away from home, ensuring their academic, emotional, and social well-being.</p>
                            <div className='block_shadow'>
                                <h3>A Home Away From Home</h3>
                                <p>The boarding life at St. George’s College is built on the values of trust, respect, and community. Our hostel is a close-knit environment where students live, learn, and grow together. With a dedicated staff of housemasters, matrons, and counselors, we ensure that every student’s needs are taken care of and that they feel supported throughout their stay.</p>
                            </div>
                            <div className='block_shadow'>
                                <h3>World-Class Facilities</h3>
                                <p>Our well-equipped boarding facilities include:</p>
                                <ul>
                                    <li>Comfortable Dormitories: Spacious and clean dormitories designed to foster camaraderie and a sense of belonging.</li>
                                    <li>Dining Hall: Nutritious, balanced meals are served in a communal dining area, ensuring that students have access to healthy food.</li>
                                    <li>24/7 Medical Care: A resident nurse and access to nearby healthcare facilities ensure that students’ health is always a priority.</li>
                                </ul>
                            </div>
                            <div className='block_shadow'>
                                <h3>Holistic Development</h3>
                                <p>Boarding life at St. George’s College is designed to go beyond academics. We focus on the holistic development of our students, encouraging participation in a wide range of extracurricular activities. From sports, drama, and music to leadership opportunities, students are provided with numerous avenues to develop their talents and interests.</p>
                            </div>
                            <div className='block_shadow'>
                                <h3>Routine & Discipline</h3>
                                <p>We believe in maintaining a structured environment that helps students build discipline and time management skills. Our daily routine is thoughtfully designed to balance academics, personal time, and recreational activities. Students are guided in creating study habits that help them excel academically while also enjoying their time in the hostel.</p>
                            </div>
                            <div className='block_shadow'>
                                <h3>Weekend Activities & Outings</h3>
                                <p>Weekends in the hostel are a time for relaxation, exploration, and fun. We organize various activities such as Saturday movie nights, sports competitions and monthly excursions to nearby locations. These outings help students unwind, bond with their peers, and experience the beauty of Mussoorie’s natural surroundings.</p>
                            </div>
                            <div className='block_shadow'>
                                <h3>Cultural & Festival Celebrations</h3>
                                <p>At St. George’s College, we celebrate various cultural festivals and important occasions as a boarding community. These celebrations bring the entire hostel together, fostering unity and appreciation for different cultures and traditions.</p>
                            </div>
                            <div className='block_shadow'>
                                <h3>Supportive Community</h3>
                                <p>Living in the hostel offers students a chance to build lifelong friendships and learn essential life skills like teamwork, cooperation, and empathy. Our boarding staff and peer mentors create a supportive, encouraging environment that helps students navigate the challenges of boarding life while forming strong bonds with their classmates.</p>
                            </div>
                            <div className='block_shadow'>
                                <h3>Safety & Security</h3>
                                <p>Student safety is our top priority. Our hostel is equipped with round-the-clock security, CCTV surveillance, and secure access systems to ensure a safe and secure environment for all boarders.</p>
                            </div>
                            <p>At St. George’s College, boarding life is about more than just living on campus. It’s about building character, creating memories, and forming lasting connections that shape students into responsible, confident, and well-rounded individuals.</p>
                            <p>For more information or to schedule a tour of our boarding facilities, please contact our <b>Admissions Office at 70603 11771</b>.</p>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </>
    )
}
export default Hostel