
import React, { useEffect, useState } from 'react';
import { getEvents } from "../Service/Api";
import { getNews } from '../Service/Api';
import Header from '../component/Header'
import Slider from '../component/Slider'
import Highlight from '../component/Highlights'
import Journey from '../component/Journey'
import PrincipalMessage from '../component/PrincipalMessage'
import Events from '../component/Events'
import Topper from '../component/Topper'
import AcademicCalender from '../component/AcademicCalender'
import Fest from '../component/Fest'
import Gallery from '../component/Gallery'
import Footer from '../component/Footer'
import Birthday from '../component/Birthday'
import Notification from '../component/Notification'
import MainCounter from '../component/MainCounter'
import Partners from '../component/Partner'
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css';
import FacebookPagePlugin from '../component/Facebook';
const Home = () => {
    const [data, setData] = useState([]);
    const [NewsData, setNewsData] = useState([]);
    const [active1, setActive1] = useState("active");
    const [active2, setActive2] = useState("");
    const [active3, setActive3] = useState("");
    const [type, setType] = useState('All');


    useEffect(() => {
        const getData = async (type) => {
            console.log(type)
            const datas = await getEvents();
            const newsData = await getNews();
            console.log(datas);
            console.log(newsData);
            if (type === 'All') {
                setData(datas);
                setActive1("active");
                setActive2("");
                setActive3("");
                setType(newsData);
            } else if (type === 'News') {
                setData([]);
                setActive1("");
                setActive2("active");
                setActive3("");
                setNewsData(newsData);
            } else if (type === 'Event') {
                setData(datas);
                setNewsData([]);
                setActive1("");
                setActive2("");
                setActive3("active");
            }

        };
        getData(type);
    }, [type]);

    const handleChange = (type) => {
        setType(type)
    }


    useEffect(() => {
        console.log(type)
    }, [type])


    useEffect(() => {
        AOS.init({
            duration: 1000, offset: 150, // You can customize the duration and other settings here
        });
    }, []);
    setTimeout(function () {
        document.querySelector(".slider-main").style.minHeight = "auto"
    }, 2000)


    const [visible, setVisible] = useState(false);

    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => window.removeEventListener('scroll', toggleVisibility);
    }, []);
    return (
        <>
            <Notification />
            <Header></Header>
            <Slider></Slider>
            <Highlight />
            <div className='col-md-2 '>
                <p className="social-media">
                    <a href="https://www.instagram.com/st.georgescollegeofficial/?hl=en" target='_blank'><img src='/instagram.png'></img></a>
                    <a href="https://www.youtube.com/StGeorgesCollegeMussoorie" target='_blank'><img src='/youtube.png'></img></a>
                    <a href="https://www.facebook.com/StGeorgesCollegeOfficial/" target='_blank'><img src='/facebook.png'></img></a>
                </p>
            </div>
            <div className='about' data-aos="fade-up">
                <div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <img src='/about.png' className='img-fluid' />
                            </div>
                            <div className='col-md-8'>
                                <div className='abt-cont-blk'>
                                    <h2 className='title_main'><span>171<small>+</small></span> Years of Dedicated Service</h2>
                                    {/* <p>Standing majestically on a hill, a cut above the rest, overlooking a small Never Never Land called 'Barlowganj' is the 161 years old Manor House, where live the Manorites. The spark of inspiration, which was first ignited by Fr. Barry and the Capuchin Friars in the year 1853, has been sheltered and nurtured by the Patrician Brothers. Over these years, the school has grown and developed in leaps and bounds. The times are changing and so is St. George’s. This top school in Dehradun is committed to providing the highest quality of education to its pupils using English as a tool. The thrust and the emphasis is the same but it has changed with the best in education and infrastructure. The school, an all-boys residential and non-residential institution, spreading over 400 acres of land. Since its inception, St. George’s College has helped shape our country. This top boarding school in Dehradun was opened in a cottage known as Manor House – the name by which the Campus is still known.</p> */}
                                    <p>Situated in Barlowganj, a sleepy village nestling in the foothills of the Himalayas, St.George’s College was established in the year 1853, by the Brothers of the Patrician Congregation. Manor House, as the college is more popularly known , began in an attempt to provide quality education to British Catholic children, on the lines of the  Public School System in Britain. Affiliated to the Council for Indian School Certificate Examinations (CISCE), St. George’s College is a residential cum day school for students of classes  four to twelve. </p>
                                    <p>Generations of young men have graduated from the portals of this venerable institution, imbued with the cherished qualities of undiminished  fortitude, honour, and resilience. Distinguished among these, is a long list of celebrated sportsmen, religious leaders, educationists, statesmen and bureaucrats, stalwarts of the Defence Forces, actors and musicians.  </p>
                                    <p>With the mission of promoting  lifelong learning in an open and caring atmosphere that motivates students to be confident and responsible global citizens , Manor House strives to create a home away from home where universal brotherhood is imbibed and nurtured for a lifetime along with the desire to contribute positively through selfless service, putting the totality of God-given talents and acquired knowledge to the service of humanity. </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="journey" data-aos="fade-up">
                <Journey />
            </div>
            <div className='life-our-school'>
                <div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <h2 className='title_main m-auto'><small>Life At</small><span>O</span>ur School</h2>
                            </div>

                        </div>
                        <div className="row" data-aos="fade-up">
                            <div className='col-sm-3'>
                                <div className='img-block'>
                                    <img src="lifeimg1.png" />
                                </div>
                                <div className='content-blk arrow-top'>
                                    <h3>Milestone</h3>
                                    <a href='https://sgcmilestone.com/home.html' target='_blank'>READ MORE</a>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className='d-flex'>
                                    <div className='content-blk arrow-right'>
                                        <h3>Latest News & Events</h3>
                                        <a href='/Events'>READ MORE</a>
                                    </div>
                                    <div className='img-block'>
                                        <img src="lifeimg2.png" />
                                    </div>

                                </div>
                                <div className='virtual'>
                                <Link to="/virtual-tour">
                                    <img src='360-view.gif' />
                                    <h3>Take a 360° Virtual Tour</h3>
                                    </Link>
                                </div>
                            </div>
                            <div className='col-sm-3'>
                                <div className='content-blk arrow-bottom'>
                                    <h3>School Events & Programs</h3>
                                    <a href='#'>READ MORE</a>
                                </div>
                                <div className='img-block'>
                                    <img src="lifeimg3.png" />
                                </div>

                            </div>
                            <div className='col-sm-3'>

                                <div className='img-block'>
                                    <img src="lifeimg4.png" />
                                </div>

                            </div>
                            <div className='col-sm-3'>
                                <div className='content-blk arrow-left'>
                                    <h3>Career Information, Education & Guidance</h3>
                                    <a href='/career-information'>READ MORE</a>
                                </div>

                            </div>
                            <div className='col-sm-3'>

                                <div className='img-block '>
                                    <img src="lifeimg5.png" />
                                </div>

                            </div>
                            <div className='col-sm-3'>
                                <div className='content-blk arrow-left'>
                                    <h3>Hostel / Boarding Life</h3>
                                    <a href='/hostel'>READ MORE</a>
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
            <div className='message-desk'>
                <div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-3' data-aos="fade-left">
                                <div class="message-title">
                                    <img src='quote.png' className='quote' />
                                    <h2 className='title_main m-auto'><small>From The</small><span>M</span>essage  <br></br> &nbsp; &nbsp; &nbsp; &nbsp;Desk</h2>
                                </div>
                            </div>
                            <div className='col-md-9 animateMe' data-aos="fade-right">
                                <PrincipalMessage />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='fest-bg'>
                <div className="title message-title"><h2 className="title_main m-auto"> <span>E</span>vents at SGC </h2></div>
                <div className='fest'>
                    <div>
                        <div className='container-fluid'>
                            <div className='row'>


                                <div className='col-md-12'>
                                    <Fest />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='main-birthday'>
                <div class='birthday'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="title message-box">
                                    <h2 className='title_main m-auto'> <span>B</span>irthday<br></br>Section <img src='/open-gift.gif'></img></h2>
                                </div>
                            </div>
                            <Birthday />
                        </div>
                    </div>
                </div>
            </div>
            <div className='counter_section'>
                <MainCounter />
            </div>
            <div className='educationPartner'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='d-flex'> 
                        <h2 class="title_main m-auto"> <i><span>E</span>ducation </i> Partners </h2> 
                            <div class="marquee">
                                <div class="track">
                                    <Partners />
                                </div>
                            </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="topper">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-3'>
                            <img src="toppers.png" />
                        </div>
                        <div className='col-md-9' data-aos="fade-up"><Topper /></div>
                    </div>
                </div>
            </div>
            <div className='news-events'>
                <div className='news-event-filter'>
                    <div className={`all ${active1}`} onClick={() => handleChange('All')}>All</div>
                    <div className={`news ${active2}`} onClick={() => handleChange('News')}>News</div>
                    <div className={`events ${active3}`} onClick={() => handleChange('Event')}>Events</div>
                </div>
                <div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-8 animateMe' data-aos="fade-left">
                                <div className='evt-block'>
                                    <div className="title">
                                        <h2 className='title_main m-auto'><small>Important</small><span>N</span>ews & <br />Events</h2>
                                        <div className='button'> <Link to="/Events">VIEW All</Link></div>
                                    </div>
                                    <Events data={data} NewsData={NewsData} />
                                </div>
                            </div>
                            <div className='col-lg-4 animateMe' data-aos="fade-right">
                                <AcademicCalender />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='gallery animateMe' data-aos="fade-up">
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <Gallery />
                        </div>
                    </div>
                </div>
            </div>
            <div className='facebook'>
                <div>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-12'>
                                <FacebookPagePlugin />
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <Footer data-aos="fade-up" />
            <div className="scroll-up-button">
                {visible && (
                    <button onClick={scrollToTop} className="scroll-to-top">
                        <i class="bi bi-arrow-up"></i>
                    </button>
                )}
            </div>
        </>
    )
}
export default Home